<template>
	<div class="container">
		<div class="Title">
			<p>{{DetailData.name}}</p>
			<div class="icon-return" @click="$router.back()"></div>
		</div>
		<Details :DetailData="DetailData" :NumIdx="NumIdx" @openRule="G_Show = true"></Details>
		<Dynamic :DetailList="DetailList" :Awardlist="Awardlist" :Dynamic_checked="Dynamic_checked" :Dynamic_num="Dynamic_num" @setAnimation="setAnimation" @setEquipment="setEquipment" v-show="AnimationShow == '1'" :Music_checked="Music_checked"></Dynamic>
		<div class="price" v-if="type!='double'" style="justify-content: center;margin: 6px 0 15px;font-size: 17px;">
			<div class="coins"></div>{{(DetailData.bean*NumIdx).toFixed(2)}}
		</div>
		<div class="box_num" v-if="type!='double'" v-show="AnimationShow == '0'">
			<p v-for="(i,index) in 5" :class="{'active' : NumIdx == index + 1}" @click="NumIdx = index + 1">x{{i}}</p>
		</div>
		<div class="open_btn" v-show="AnimationShow == '0'">
			<!-- <div class="Animation_switch" style="margin-bottom: 10px">
				<p>跳过动画</p>
				<van-switch v-model="Animation_checked" size="24px" />
			</div> -->
			<div class="btn" @click="PostOpen"></div>
			<!-- <div class="tit">
				<van-icon name="question-o" color="#fff" size="20"/>
				<p @click="G_Show = true">玩法规则</p>
			</div> -->
		</div>
		<div class="switch-con">
			<div class="skip-con">
				开启音乐
				<van-switch v-model="Music_checked" size="16px" />
			</div>
			<div class="skip-con">
				跳过动画
				<van-switch v-model="Animation_checked" size="16px" />
			</div>
		</div>
		<!-- <Equipment :Dynamic_num="Dynamic_num" :EquipmentItem="EquipmentItem" v-show="EquipmentShow == '1'" :key="EquipmentShow"></Equipment> -->
		<Goods :DetailList="DetailList" :id="DetailData.id"></Goods>
		<Show v-show="WinningShow" :Music_checked="Music_checked" :WinningShow="WinningShow" @setShow="setShow" :Awardlist="Awardlist"></Show>
		
		<van-overlay :show="G_Show">
			<div class="wrapper">
				<div class="rule-popup-con">
					<div class="rule-popup-header">
						宝箱规则
						<div class="close" @click="G_Show = false"></div>
					</div>
					<div class="rule-popup-main">
						<div class="rule-popup-content" v-html="publicConfig.desc_box"></div>
					</div>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import Details from '@/components/PubOpen/Details.vue'
	import Dynamic from '@/components/PubOpen/Dynamic.vue'
	import Equipment from '@/components/PubOpen/Equipment.vue'
	import Goods from '@/components/PubOpen/Goods.vue'
	import Show from '@/components/PubOpen/Show.vue'
	import { BoxDetail, Open, PersonalInfo, activity20231210Info, activity20231210Draw } from '@/network/api.js'
	import { mapMutations, mapState } from 'vuex'
	export default {
		name: 'Open',
		data() {
			return {
				DetailData:{},//箱子信息
				NumIdx:1,//开箱数量
				DetailList:[],//箱内物品
				Animation_checked: false, //动画开关
				Music_checked: true, //音乐开关
				WinningShow:false,//奖品遮罩层
				Awardlist:[],//获得奖品数据
				Dynamic_checked:'0',//播放动画('0':不播放,'1':播放)
				Dynamic_num:0,//奖品数量
				AnimationShow:'0',//动画显示隐藏'0':不显示,'1':显示)
				EquipmentItem:{},//下方奖品栏子级
				EquipmentShow:'0',//下方奖品栏显示
				G_Show:false,
				type: '',
				activityInfo:{
					user:{
						act_bean: 0
					}
				}
			}
		},
		computed: {
			...mapState(['publicConfig'])
		},
		created() {
			this.GetBoxDetail()
			window.scrollTo(0,0)
			localStorage.setItem('delay', 5)
			if(this.$route.query.type == 'double'){
				this.type = this.$route.query.type
				this.GetInfo()
			}
			
		},
		watch: {
			Animation_checked(val){
				if(val){
					localStorage.setItem('delay', 0)
				}else{
					localStorage.setItem('delay', this.NumIdx>2 ? 8 : 5)
				}
			},
			NumIdx(val){
				if(!this.Animation_checked){
					localStorage.setItem('delay', this.NumIdx>2 ? 8 : 5)
				}
			}
		},
		methods:{
			//福利箱列表
			GetInfo(){
				activity20231210Info().then((res) => {
					const {data} = res.data;
					this.activityInfo = data
				})
			},
			//宝箱详情
			GetBoxDetail(){
				BoxDetail(this.$route.query.id).then((res) => {
					// console.log(res.data.data)
					this.DetailData = res.data.data
					this.DetailList = res.data.data.box_awards.sort(function(a,b){
						return Number(b.bean) - Number(a.bean)
					})
					// console.log(this.DetailList)
				})
			},

			//开箱
			PostOpen(){
				if(this.type == 'double'){
					let params = {
						type: 3,
						times: this.NumIdx,
						box_id: this.$route.query.id
					}
					activity20231210Draw(params).then((res) => {
						const {data} = res.data
						this.GetPersonalInfo()
						this.Awardlist = res.data.data.awards
						if(this.Animation_checked == true){
							this.WinningShow = true
						}else{
							this.AnimationShow = '1'
							this.EquipmentShow = '1'
							// console.log('false')
							setTimeout(() => {
								this.Dynamic_checked = '1'
								this.Dynamic_num = this.Awardlist.length
							},100)
						}
					})
				}else{
					Open(this.$route.query.id,this.NumIdx).then((res) => {
						// console.log(res.data.data.awardlist)
						this.GetPersonalInfo()
						this.Awardlist = res.data.data.awardlist
						if(this.Animation_checked == true){
							this.WinningShow = true
						}else{
							this.AnimationShow = '1'
							this.EquipmentShow = '1'
							// console.log('false')
							setTimeout(() => {
								this.Dynamic_checked = '1'
								this.Dynamic_num = this.Awardlist.length
							},100)
						}
					})
				}
				
			},

			//个人信息
			GetPersonalInfo() {
				PersonalInfo().then((res) => {
					this.PostUser(res.data.message)
				})
			},

			//接收子级(关闭奖品遮罩层)
			setShow(v){
				// console.log(v)
				this.WinningShow = v
				this.EquipmentShow = '0'
			},

			//接收子级(动画结束)
			setAnimation(v){
				this.WinningShow = v
				this.AnimationShow = '0'
				this.Dynamic_checked = '0'
			},

			//接收子级(下方奖品栏)
			setEquipment(v){
				// console.log(v)
				this.EquipmentItem = this.Awardlist[v - 1]
			},

			...mapMutations([
				'PostUser'
			])
		},

		components: {
			Details,
			Dynamic,
			Equipment,
			Goods,
			Show
		}
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	.Title{
		background-size: 100% auto;
		background-position: bottom center;
		background-repeat: no-repeat;
		width: 363px;
		background-image: url(~@/assets/img/home-title-bg.png);
		.icon-return{
			position: absolute;
			top: 50%;
			left: 14px;
			width: 24px;
			height: 24px;
			background-size: 100% 100%;
			background-image: url(~@/assets/img/icon-return.png);
			cursor: pointer;
			transform: translateY(-50%);
		}
	}
	.container {
		.box_num{
			display: flex;
			justify-content: center;
			p{
				width: 50px;
				height: 44px;
				background: #232749;
				text-align: center;
				line-height: 44px;
				margin: 0 3px;
				cursor: pointer;
				&.active{
					background: var(--logo, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
					text-shadow: 0px 2px 4px #3A6EB5;
				}
			}
		}
		.open_btn {
			text-align: center;
			cursor: pointer;
			.btn{
				background: url(~@/assets/img/box-open-bg.png);
				background-size: 100% 100%;
				width: 274px;
				height: 55px;
				@include HScenter;
				margin: 14px auto 0;
			}
		}
	}
	.switch-con{
		@include Hcenter;
		gap: 12px;
		.skip-con{
			@include HScenter;
			font-size: 12px;
			color: #999;
			margin-top: 16px;
			.van-switch{
				border: none;
				margin-left: 6px;
			}
			.van-switch--on{
				background: var(--logo, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
			}
		}
	}
</style>
<style lang="scss" scoped>
	.times{
		opacity: 0;
		color: #ffba2e;
		text-align: center;
		margin-top: -20px;
		font-size: 14px;
		margin-bottom: 15px;
		&.show{
			opacity: 1;
		}
	}
</style>
